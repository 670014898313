import { LocalStorageRepository } from '../repositories/local-storage.repository';
import { HttpInterceptorService } from './http-interceptor.service';

const profileServiceUrl = process.env.REACT_APP_PROFILE_SERVICE_URL;

const isNotHttpCodeSuccess = (payload) => payload.statusCode && payload.statusCode !== 200;

export class ProfileService {
  static async createNewUser(body) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/register`, config);
        return await response.json();
      });

      if (!payload) {
        throw new Error('Not data error');
      }
      return payload;
    } catch (e) {
      console.warn('[CREATE_NEW_USER] error: ', e);
      return null;
    }
  }

  static async getInfo() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_PROFILE] error: ', e);
      return null;
    }
  }

  static async updateAll(body) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[UPDATE_PROFILE] error: ', e);
      return null;
    }
  }

  static async createNewSubscription() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/subscription`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[CREATE_NEW_SUBSCRIPTION] error: ', e);
      return null;
    }
  }

  static async updateAllSubscription(body) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/subscription`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[UPDATE_SUBSCRIPTION] error: ', e);
      return null;
    }
  }

  static async getSubscription() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/subscription`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_SUBSCRIPTION] error: ', e);
      return null;
    }
  }

  static async getSubUserSubscription() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/sub-user/subscription`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_SUBUSER_SUBSCRIPTION] error: ', e);
      return null;
    }
  }

  static async getSubUsersFromUserId(userId) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/admin/sub-user/${userId}`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_SUBUSER_LIST] error: ', e);
      return null;
    }
  }

  static async getSubUsers() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/sub-user`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_SUBUSER_LIST] error: ', e);
      return null;
    }
  }

  static async createNewSubUser(body) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/sub-user/register`, config);
        return await response.json();
      });

      return payload;
    } catch (e) {
      console.warn('[CREATE_NEW_SUBUSER] error: ', e);
      return null;
    }
  }

  static async deleteSubUser(uid) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/sub-user/${uid}`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[DELETE_SUBUSER] error: ', e);
      return null;
    }
  }

  static async updateSubUserWhiteLabel(body) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/sub-user/white-label`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[UPDATE_SUBUSER_WHITELABEL] error: ', e);
      return null;
    }
  }

  static async changePassword(body) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/password/change`, config);
        return await response.json();
      });

      return payload;
    } catch (e) {
      console.warn('[PROFILE_CHANGE_PASSWORD]', e);
      return null;
    }
  }

  static async createSetupIntent(body) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/payment/intent/setup`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[CREATE_PAYMENT_INTENT] error: ', e);
      return null;
    }
  }

  static async registerFirstLogin() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${profileServiceUrl}/profile/subscription/firstlogin`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[REGISTER_FIRST_LOGIN] error: ', e);
      return null;
    }
  }
}
