/**
 * Notifications Globals for showNotification action
 * Notifications that can be launched from anywhere in the app
 * It is not for specific modules in that case use showCustomDialog action
 */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateStatusPopup } from '../utils/notifications.utils';
import ExpiryModal from '../components/organisms/modals/expiry-modal.component';
import AccessRestrictedModal from '../components/organisms/modals/access-restricted-modal.component';
import DocumentRestrictedDialog from '../components/molecules/documents/document-restricted-dialog.component';
import PaymentDialogContainer from './payment-dialog.container';
import { genericConstants, stripeTransactionType } from '../factories/constant';

const NotificationDialogContainer = ({notification, onCloseNotification}) => {

  const handleCloseNotification = () => {
    onCloseNotification();
    updateStatusPopup(false);
  }; 

  const renderNotification = () => {
    if (notification && notification.notificationId) {
      switch(notification.notificationId) {
        case genericConstants.NOTIFICATION_OPERATION.ACCESS_RESTRICTED:
          return (<AccessRestrictedModal onClose={handleCloseNotification} />);
        case genericConstants.NOTIFICATION_OPERATION.DOCUMENT_RESTRICTED:
          return (<DocumentRestrictedDialog onClose={handleCloseNotification} />);
        case genericConstants.NOTIFICATION_OPERATION.EXPIRE_SESSION:
          return (<ExpiryModal onClose={handleCloseNotification} />);
        case genericConstants.NOTIFICATION_OPERATION.PROMO_PLAN_PAYMENT:
          return (<PaymentDialogContainer typeNotice={notification.notificationId} typeIntent={stripeTransactionType.CONFIRM_SETUP_PROMO} onClose={handleCloseNotification} />);
        case genericConstants.NOTIFICATION_OPERATION.EXPIRATION_FREE_PLAN_REMINDER:
        case genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_FREEPLAN_EXPIRED:
        case genericConstants.NOTIFICATION_OPERATION.SUBSCRIPTION_NOW:
        case genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_DOCUMENT_LIMIT_EXPIRED:
          return (<PaymentDialogContainer typeNotice={notification.notificationId} typeIntent={stripeTransactionType.CONFIRM_SETUP} onClose={handleCloseNotification} />);
        case genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_DEBTOR_PLAN:
          return (<PaymentDialogContainer typeNotice={notification.notificationId} onClose={handleCloseNotification} />);
        case genericConstants.NOTIFICATION_OPERATION.ENABLE_SUSCRIPTION_NOW:
        case genericConstants.NOTIFICATION_OPERATION.OPEN_KEY_ACTIVATION:
          return (<PaymentDialogContainer typeNotice={notification.notificationId} onClose={handleCloseNotification} />);
        default:
          return (<></>);
      }
    } else {
      return (<></>);
    }
  };

  return (
    <>
      {renderNotification()}
    </>
  );
};

NotificationDialogContainer.defaultProps = {
  notification: null,
  onCloseNotification: () => {},
};

NotificationDialogContainer.propTypes = {
  notification: PropTypes.object,
  onCloseNotification: PropTypes.func,
};

const mapStateToProps = (state) => ({
  notification: state.notification,
});

export default connect(mapStateToProps, null)(NotificationDialogContainer);
