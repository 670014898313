import {actionTypeConstant, genericConstants} from '../factories/constant';
import {sessionManager} from "../factories/sessionManager";

// TODO: Cookie
const user = sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.USER);

let initialState = {
    userRegistered: null,
    codeVerified: false,
    profileUpdated: false,
    user: null,
    isLoggedIn: false,
    loginSuccess: null,
    isForgotPasswordSuccess: false,
    isExpiredToken: false,
    socket: {
        hasConnection: false,
        socket: null,
    },
    subusers: [],
};

initialState = user ? {
    isLoggedIn: true,
    user,
    loginSuccess: null,
    userRegistered: null,
    isForgotPasswordSuccess: false,
    socket: {
        hasConnection: false,
        socket: null,
    },
    subusers: [],
} : initialState;

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypeConstant.SIGN_UP_SUCCESS:
            return {
                ...state,
                user: action.data,
                isLoggedIn: true,
                userRegistered: true
            };
        case actionTypeConstant.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.data,
                isLoggedIn: true,
                loginSuccess: true
            };
        case actionTypeConstant.UPDATE_SUCCESS:
            return {
                ...state,
                user: action.data,
                isLoggedIn: true,
                loginSuccess: true
            };
        case actionTypeConstant.LOGOUT_SUCCESS:
            return {
                ...state,
                user: null,
                document: null,
                isLoggedIn: false,
                loginSuccess: false,
                codeVerified: false,
                socket: {
                    hasConnection: false,
                    socket: null,
                },
                subusers: [],
            };
        case actionTypeConstant.SET_SUBUSERS:
            return {
                ...state,
                subusers: action.data,
            };
        case actionTypeConstant.SOCKET_CONNECTION: 
            return {
                ...state,
                socket: {
                    hasConnection: action.data.hasConnection,
                    socket: state.socket.socket,
                },
            };
        case actionTypeConstant.SET_SOCKET: 
            return {
                ...state,
                socket: {
                    hasConnection: state.socket.hasConnection,
                    socket: action.data.socket,
                },
            };
        case actionTypeConstant.SET_EXPIRE_TOKEN:
            return {
                ...state,
                isExpiredToken: action.data,
            };
        case actionTypeConstant.SET_USER_VERIFY:
            return {
                ...state,
                codeVerified: true,
            };
        default:
            return state;
    }
}
