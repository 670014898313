import {genericConstants} from '../constant';
import {getSignedURL} from '../AWSService';

class UserModel {
  constructor(user) {
    this.sessionToken = '';
    this.apiKey = '';
    this.userID = '';
    this.fullName = '';
    this.firstName = '';
    this.openPayCustomerID = '';
    this.lastName = '';
    this.secondName = '';
    this.companyName = '';
    this.industry = '';
    this.activity = '';
    this.privateKey = ''; //encrypted using username and password
    this.publicKey = ''; ////encrypted using username and password
    this.emailID = '';
    this.dob = null;
    this.phone = '';
    this.addedOn = '';
    this.isFreeTrailUsed = false;
    this.modifiedOn = '';
    this.redirectUrl = '';
    this.satSignature = {
      certificate: '',
      addedOn: ''
    };
    this.eSignatures = [{
      fileName: '',
      addedOn: '',
    }];
    this.signature = [];
    this.officialID = {
      fileName: '',
      addedOn: ''
    };
    this.subscription = {
      isSubscribed: false,
      subscriptionID: '',
      purchasedDate: '',
      integration: [{
        type: '',//blockchain,IDVerification
        value: ''//true or false
      }],
      userLimit: 0,
      amount: 0,
      bioCreditsID: 0,
      bioCreditsFaceID: 0,
      backgroundCheckCredits: 0,
      ocrCheckCredits: 0,
      identityCreditsIncodeP1: 0,
      identityCreditsIncodeP2: 0,
      identityCreditsIncodeP3: 0,
      documentLimit: 0,
      expiryDate: '',
      transactionID: '',
      planName: 'TRIAL_1',
      metadata: {},
      isCorporate: false,
      isSubscriptionCancelled: true,
      subscriptionFailed : false,
      errorMessage : '',
      isWhiteLabel : false,
      disableSignatureUrlInMail : false,
      authenticateNotification : false,
      authenticateUrl : '',
      whiteLabel : {},
      isMassiveSignature : false,
      isEndorsementMassiveSignature: false,
      isFormat: false,
      isOrderSign : false,
      saleName: 'Weesign',
      identityProvider: 'VERIDOC',
      isSignatureText: false,
      defaultCountry: '',
      autocaptureSDKEnabled: false,
      isPassiveLiveness: false,
      whatsappCredits: 0,
      hideDeleteDocumentSubUser: false,
      whatsappConsentAccepted: false,
      attachmentConsentAccepted: false,
      isEndorsementSpecial: false,
      staticPositionVersion: '',
      isDisableDrift: false,
    };
    this.addedBy = '';
    this.role = '';
    this.isAdmin = false;
    this.isNewUser = false;
    this.firstLoginDate = null;
    this.hasRegisteredCard = false;
    this.permissionsDenied = [];
    if (user)
      this.setData(user);
  }

  setData(user) {
    this.userID = user.userID || '';
    this.sessionToken = user.sessionToken || '';
    this.apiKey = user.apiKey || '';
    this.openPayCustomerID = user.openPayCustomerID || '';
    this.fullName = user.fullName || '';
    this.firstName = user.firstName || '';
    this.lastName = user.lastName || '';
    this.secondName = user.secondName || '';
    this.companyName = user.companyName || '';
    this.activity = user.activity || '';
    this.industry = user.industry || '';
    this.privateKey = user.privateKey || ''; //encrypted using username and password
    this.publicKey = user.publicKey || ''; ////encrypted using username and password
    this.emailID = user.emailID || '';
    this.isFreeTrailUsed = user.isFreeTrailUsed === true;
    this.dob = user.dob ? user.dob : null;
    this.phone = user.phone || '';
    this.addedOn = user.addedOn || new Date().getTime();
    this.modifiedOn = user.modifiedOn || new Date().getTime();
    this.satSignature = {};
    this.redirectUrl = user.redirectUrl || '';

    let eSignatures = [];
    if (user.eSignatures && user.eSignatures.length) {
      user.eSignatures.forEach((signature) => {
        if (!signature.fileName)
          return;
        let eSignature = {
          fileName: signature.fileName || '',
          addedOn: signature.addedOn || ''
        };
        eSignatures.push(eSignature);
      });
    }
    this.eSignatures = eSignatures;

    let signature = [];
    if (user.signature && user.signature.length) {
      user.signature.forEach((signatureObj) => {
        if (!signatureObj.fileName)
          return;
        let obj = {
          fileName: signatureObj.fileName || '',
          profilePhotoName: (signatureObj.profilePhotoName && signatureObj.profilePhotoName.length>0)?signatureObj.profilePhotoName : [],
          //  svgCoordinates: (signatureObj.svgCoordinates && signatureObj.svgCoordinates.length>0)?signatureObj.svgCoordinates : [],
          signatureURL: signatureObj.fileName ? getSignedURL(signatureObj.fileName) : ''
        };
        signature.push(obj);
      });
    }
    this.signature = signature;

    if (user.officialID && user.officialID.fileName) {
      this.officialID = {
        fileName: user.officialID.fileName || '',
        addedOn: user.officialID.addedOn || ''
      };
    } else {
      this.officialID = {};
    }

    if (user.subscription) {
      this.subscription = {
        subscriptionFailed : user.subscription.subscriptionFailed || false,
        errorMessage : user.subscription.errorMessage || '',
        isSubscribed: user.subscription.isSubscribed || false,
        subscriptionID: user.subscription.subscriptionID || '',
        purchasedDate: user.subscription.purchasedDate || '',
        integration: user.integration && user.integration.length ? user.integration : [],
        userLimit: user.subscription.userLimit ? typeof user.subscription.userLimit === 'string' ?
          parseInt(user.subscription.userLimit):user.subscription.userLimit: 0,
        amount: user.subscription.amount ? typeof user.subscription.amount === 'string' ?
          parseInt(user.subscription.amount):user.subscription.amount: 0,
        bioCreditsID: user.subscription.bioCreditsID ? typeof user.subscription.bioCreditsID === 'string' ?
          parseInt(user.subscription.bioCreditsID):user.subscription.bioCreditsID: 0,
        bioCreditsFaceID: user.subscription.bioCreditsFaceID ? typeof user.subscription.bioCreditsFaceID === 'string' ?
          parseInt(user.subscription.bioCreditsFaceID):user.subscription.bioCreditsFaceID: 0,
        backgroundCheckCredits: user.subscription.backgroundCheckCredits ? typeof user.subscription.backgroundCheckCredits === 'string' ?
          parseInt(user.subscription.backgroundCheckCredits):user.subscription.backgroundCheckCredits: 0,
        ocrCheckCredits: !isNaN(user.subscription.ocrCheckCredits) ? parseInt(user.subscription.ocrCheckCredits) : 0,
        identityCreditsIncodeP1: !isNaN(user.subscription.identityCreditsIncodeP1) ? parseInt(user.subscription.identityCreditsIncodeP1) : 0,
        identityCreditsIncodeP2: !isNaN(user.subscription.identityCreditsIncodeP2) ? parseInt(user.subscription.identityCreditsIncodeP2) : 0,
        identityCreditsIncodeP3: !isNaN(user.subscription.identityCreditsIncodeP3) ? parseInt(user.subscription.identityCreditsIncodeP3) : 0,
        documentLimit: user.subscription.documentLimit ? typeof user.subscription.documentLimit === 'string' ?
          parseInt(user.subscription.documentLimit):user.subscription.documentLimit: 0,
        expiryDate: user.subscription.expiryDate || '',
        transactionID: user.subscription.transactionID || '',
        planName: user.subscription.planName || 'TRIAL_1',
        metadata: user.subscription.metadata || true,
        isSubscriptionCancelled: user.subscription.isSubscriptionCancelled || true,
        isCorporate: user.subscription.isCorporate || false,
        isActivateFaceID: user.subscription.isActivateFaceID || false,
        isActivateID: user.subscription.isActivateID || false,
        isActivateBackgroundCheckValidation: user.subscription.isActivateBackgroundCheckValidation || false,
        isGeolocationActivate: user.subscription.isGeolocationActivate || false,
        isDisabledEmailSend: user.subscription.isDisabledEmailSend || false,
        changedSubscription:{
          planName: user.subscription.changedSubscription && user.subscription.changedSubscription.planName ? user.subscription.changedSubscription.planName:'',
          subscriptionID: user.subscription.changedSubscription && user.subscription.changedSubscription.subscriptionID ? user.subscription.changedSubscription.subscriptionID : ''
        },
        numberOfDocumentsUploaded: user.subscription.numberOfDocumentsUploaded || 0,
        numberOfUserAdded: user.subscription.numberOfUserAdded || 1,
        isWhiteLabel: user.subscription.isWhiteLabel || false,
        disableSignatureUrlInMail: user.subscription.disableSignatureUrlInMail || false,
        authenticateNotification: user.subscription.authenticateNotification || false,
        authenticateUrl: user.subscription.authenticateUrl || '',
        isMassiveSignature: user.subscription.isMassiveSignature || false,
        isEndorsementMassiveSignature: user.subscription.isEndorsementMassiveSignature || false,
        isFormat: user.subscription.isFormat || false,
        isOrderSign: user.subscription.isOrderSign || false,
        whiteLabel: {
          name: user.subscription.whiteLabel && user.subscription.whiteLabel.name ? user.subscription.whiteLabel.name : '',
          color: user.subscription.whiteLabel && user.subscription.whiteLabel.color ? user.subscription.whiteLabel.color : '',
          logo: user.subscription.whiteLabel && user.subscription.whiteLabel.logo ? user.subscription.whiteLabel.logo : '',
          signBox: user.subscription.whiteLabel && user.subscription.whiteLabel.signBox ? user.subscription.whiteLabel.signBox : '',
          biometricLogo: user.subscription.whiteLabel && user.subscription.whiteLabel.biometricLogo ? user.subscription.whiteLabel.biometricLogo : '',
          url: user.subscription.whiteLabel && user.subscription.whiteLabel.url ? user.subscription.whiteLabel.url : '',
          templateAttached: user.subscription.whiteLabel && user.subscription.whiteLabel.templateAttached ? user.subscription.whiteLabel.templateAttached : '',
          templateEfirma: user.subscription.whiteLabel && user.subscription.whiteLabel.templateEfirma ? user.subscription.whiteLabel.templateEfirma : '',
          templateEfirmaGeo: user.subscription.whiteLabel && user.subscription.whiteLabel.templateEfirmaGeo ? user.subscription.whiteLabel.templateEfirmaGeo : '',
          templateRegister: user.subscription.whiteLabel && user.subscription.whiteLabel.templateRegister ? user.subscription.whiteLabel.templateRegister : '',
          templateCertificate: user.subscription.whiteLabel && user.subscription.whiteLabel.templateCertificate ? user.subscription.whiteLabel.templateCertificate : '',
          subjectEmail: user.subscription.whiteLabel && user.subscription.whiteLabel.subjectEmail ? user.subscription.whiteLabel.subjectEmail : '',
          templateHsbc: user.subscription.whiteLabel && user.subscription.whiteLabel.templateHsbc ? user.subscription.whiteLabel.templateHsbc : false,
        },
        saleName: user.subscription.saleName || 'weetrust',
        identityProvider: user.subscription.identityProvider || 'VERIDOC',
        isSignatureText: user.subscription.isSignatureText || false,
        defaultCountry: user.subscription.defaultCountry || '',
        autocaptureSDKEnabled: user.subscription.autocaptureSDKEnabled || false,
        isPassiveLiveness: user.subscription.isPassiveLiveness || false,
        whatsappCredits: parseInt(user.subscription.whatsappCredits || 0),
        hideDeleteDocumentSubUser: user.subscription.hideDeleteDocumentSubUser || false,
        whatsappConsentAccepted: user.subscription.whatsappConsentAccepted || false,
        attachmentConsentAccepted: user.subscription.attachmentConsentAccepted || false,
        isActiveAttachments: user.subscription.isActiveAttachments || false,
        isEndorsementSpecial: user.subscription.isEndorsementSpecial || false,
        staticPositionVersion: user.subscription.staticPositionVersion || '',
        isDisableDrift: user.subscription.isDisableDrift || false,
      };
    } else {
      this.subscription = {};
    }
    this.addedBy = user.addedBy || '';
    this.role = user.role || genericConstants.USER_ROLE.USER;
    this.isAdmin = user.isAdmin || false;
    this.isNewUser = user.isNewUser ?? false;
    this.firstLoginDate = user.firstLoginDate ?? null;
    this.hasRegisteredCard = user.hasRegisteredCard ?? false;
    this.permissionsDenied = user.permissionsDenied || [];
  }
}

export default UserModel;
