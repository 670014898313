import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CardFieldPayment from './card-field.component';
import { stripeTransactionType } from '../../../factories/constant';
import { BillingService } from '../../../services/billing.service';

const AddPaymentMethod = ({typeIntent, buttonTextId, disabled, onSuccess, onError, onProcessing}) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const handleAddPaymentMethod = async(paymentId) => {
    try {
      onProcessing();
      const responseMethod = await BillingService.addPaymentMethodToCustomer(paymentId);
      if (!responseMethod) {
        throw new Error('Method Error');
      }
      if (typeIntent) {
        let responseMembership = null;
        switch (typeIntent) {
          case stripeTransactionType.CONFIRM_SETUP_PROMO:
            responseMembership = await BillingService.redeemSubscriptionPromo();
            break;
          case stripeTransactionType.CONFIRM_SETUP:
            responseMembership = await BillingService.startSubscriptionMembership();
            break;
          default:
            break;
        }
        if (responseMembership) {
          onSuccess();
        } else {
          throw new Error('Suscription Error');
        }
      } else {
        onSuccess();
      }
    } catch (e) {
      console.log("AddPaymentMethod error => ", e.message);
      onError();
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <CardFieldPayment
        buttonTextId={buttonTextId}
        disabled={disabled} 
        onSuccess={handleAddPaymentMethod}
      />
    </Elements>
  );
};

AddPaymentMethod.defaultProps = {
  typeIntent: '',
  buttonTextId: 'payment.card.setup',
  disabled: true,
  onSuccess: () => {},
  onError: () => {},
  onProcessing: () => {},
};

AddPaymentMethod.propTypes = {
  typeIntent: PropTypes.string,
  buttonTextId: PropTypes.string,
  disabled:  PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onProcessing: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AddPaymentMethod);
