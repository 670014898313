import React, {useState} from 'react'; 
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const CardFieldPayment = ({ user, buttonTextId, onSuccess, onProcessing, onError, disabled }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [messageError, setMessageError] = useState('');

  const handleSubmit = async(event) => {
    setMessageError('');
    event.preventDefault();
    if (!stripe) {
      return;
    }
    try {
      onProcessing();
      const cardElement = elements.getElement(CardElement);
      stripe.createToken(cardElement);
      const { paymentMethod, error } = await  stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: user.fullName,
          email: user.emailID,
        }
      });
      if (paymentMethod && paymentMethod.id) {
        onSuccess(paymentMethod.id);
      } else {
        throw new Error(error?.message || 'CreatePaymentMethod Error');
      }
    } catch (e) {
      console.log("Add Payment Method error", e);
      setMessageError(e.message);
      onError();
    }
  };

  const handleChangeForm = () => {
    setMessageError('');
  };

  return (
    <form onSubmit={handleSubmit} className={`add-payment-card ${disabled ? 'add-payment-card--disabled':''}`}>
      <div className="add-payment-card__title title-section-bar">
        <FormattedMessage id="payment.card.new" />
      </div>
      <div className="add-payment-card__form">
        <CardElement
          onChange={handleChangeForm}
        />
      </div>
      <div className="add-payment-card__error">
        {messageError}
      </div>
      <div className="add-payment-card__caption">
        <FormattedMessage id="payment.add.method.caption" />
      </div>
      <div className="add-payment-card__actions">
        {stripe && !disabled && (
          <div className="add-payment-card__actions__wrapper">
            <button className="wt-btn wt-btn--circle wt-btn--theme btn-confirm-payment">
              <FormattedMessage id={buttonTextId} />
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

CardFieldPayment.defaultProps = {
  disabled: true,
  buttonTextId: 'payment.card.setup',
  onError: () => {},
  onSuccess: () => {},
  onProcessing: () => {},
};

CardFieldPayment.propTypes = {
  disabled: PropTypes.bool,
  buttonTextId: PropTypes.string,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  onProcessing: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user ? state.user.user : {},
});

export default connect(mapStateToProps)(CardFieldPayment);
