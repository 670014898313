import { genericConstants } from '../factories/constant';
import moment from 'moment';

export const getUserNotifications = (user) => {
  const userNotification = {
    notification: null,
    banner: null,
  };
  const banner = JSON.parse(localStorage.getItem('notification-top-banner'));
  const popup = JSON.parse(localStorage.getItem('notification-popup'));
  const isFreePlan = user.subscription.planName === genericConstants.PLAN_NAME.TRIAL_1;
  const isFrezzedPlan = user.subscription.planName === genericConstants.PLAN_NAME.DEBTOR;
  const isZeroPlan = user.subscription.planName === genericConstants.PLAN_NAME.ZERO;
  const hasConfirmPlan = user.hasRegisteredCard; //Promo Accepted
  
  if (isFreePlan && !hasConfirmPlan) {
    const initialTimePromo = moment(user.firstLoginDate);
    const endTimePromo = moment(user.firstLoginDate).add(1, 'days');
    const expirePlanDate = user && user.subscription && user.subscription.expiryDate ? user.subscription.expiryDate : null;
    const canShowPromoEvent = moment().isSameOrAfter(initialTimePromo) && moment().isSameOrBefore(endTimePromo);
    //First 24 hours after first login
    if (canShowPromoEvent) {
      if (!banner || (banner.bannerId !== genericConstants.NOTIFICATION_OPERATION.PROMO_PLAN_PAYMENT) || banner.isShown) {
        userNotification.banner = genericConstants.NOTIFICATION_OPERATION.PROMO_PLAN_PAYMENT;
      }
      if (!popup || (popup.notificationId !== genericConstants.NOTIFICATION_OPERATION.PROMO_PLAN_PAYMENT) || popup.isShown) {
        userNotification.notification = genericConstants.NOTIFICATION_OPERATION.PROMO_PLAN_PAYMENT;
      }
    } else {
      if (expirePlanDate) {
        const expirePlanDateTimeEnd = moment(expirePlanDate, 'YYYY-MM-DD').endOf('day');
        const expirePlanDateTimeStart = moment(expirePlanDate, 'YYYY-MM-DD').startOf('day');
        //Last 24 hours before expire plan date
        if (moment().isSameOrAfter(expirePlanDateTimeStart) && moment().isSameOrBefore(expirePlanDateTimeEnd)) {
          if (!banner || (banner.bannerId !== genericConstants.NOTIFICATION_OPERATION.EXPIRATION_FREE_PLAN_REMINDER) || banner.isShown) {
            userNotification.banner = genericConstants.NOTIFICATION_OPERATION.EXPIRATION_FREE_PLAN_REMINDER;
          }
          if (!popup || (popup.notificationId !== genericConstants.NOTIFICATION_OPERATION.EXPIRATION_FREE_PLAN_REMINDER) || popup.isShown) {
            userNotification.notification = genericConstants.NOTIFICATION_OPERATION.EXPIRATION_FREE_PLAN_REMINDER;
          }
        } else {
          //Period 14 days
          if (moment().isBefore(expirePlanDateTimeEnd)) {
            if (!banner || (banner.bannerId !== genericConstants.NOTIFICATION_OPERATION.SUSCRIPTION_REMINDER) || banner.isShown) {
              userNotification.banner = genericConstants.NOTIFICATION_OPERATION.SUSCRIPTION_REMINDER;
            }
          }
          //Period expired
          if (moment().isAfter(expirePlanDateTimeEnd)) {
            if (!banner || (banner.bannerId !== genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_FREEPLAN_EXPIRED) || banner.isShown) {
              userNotification.banner = genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_FREEPLAN_EXPIRED;
            }
            if (!popup || (popup.notificationId !== genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_FREEPLAN_EXPIRED) || popup.isShown) {
              userNotification.notification = genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_FREEPLAN_EXPIRED;
            }
          }
        }
      }
    }
  }
  if (isFrezzedPlan || isZeroPlan) {
    if (!banner || (banner.bannerId !== genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_DEBTOR_PLAN) || banner.isShown) {
      userNotification.banner = genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_DEBTOR_PLAN;
    }
    if (!popup || (popup.notificationId !== genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_DEBTOR_PLAN) || popup.isShown) {
      userNotification.notification = genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_DEBTOR_PLAN;
    }
  }
  if (userNotification.banner) {
    setNotificationBanner(userNotification.banner);
  }
  if (userNotification.notification) {
    setNotificationPopup(userNotification.notification);
  }
  return userNotification;
};

export const setNotificationBanner = (bannerId) => {
  const banner = { bannerId, isShown: true };
  localStorage.setItem('notification-top-banner', JSON.stringify(banner));
};

export const setNotificationPopup = (notificationId) => {
  const popup = { notificationId, isShown: true };
  localStorage.setItem('notification-popup', JSON.stringify(popup));
};

export const updateStatusBanner = (status) => {
  const banner = JSON.parse(localStorage.getItem('notification-top-banner'));
  if (banner) {
    banner.isShown = status;
    localStorage.setItem('notification-top-banner', JSON.stringify(banner));
  }
};

export const updateStatusPopup = (status) => {
  const popup = JSON.parse(localStorage.getItem('notification-popup'));
  if(popup) {
    popup.isShown = status;
    localStorage.setItem('notification-popup',JSON.stringify(popup));
  }
};

export const cleanUserNotifications = () => {
  localStorage.removeItem('notification-popup');
  localStorage.removeItem('notification-top-banner');
  localStorage.removeItem('notification-first-login');
};

export const cleanNotificationBanner = () => {
  localStorage.removeItem('notification-top-banner');
};

export const cleanNotificationPopup = () => {
  localStorage.removeItem('notification-top-banner');
};

export const getNotificationFirstLogin = (firstLoginDate) => {
  const notificationDisplayed = localStorage.getItem('notification-first-login') ? Boolean(localStorage.getItem('notification-first-login')) : false;
  if (!notificationDisplayed) {
    const firstLogin = moment(firstLoginDate);
    const firstLoginDelay = moment(firstLoginDate).add(3, 'minutes');
    const isFirstLogin = moment().isSameOrAfter(firstLogin) && moment().isSameOrBefore(firstLoginDelay);
    if (isFirstLogin) {
      localStorage.setItem('notification-first-login', 'true');
      return true;
    }
  }
  return false;
};
